<template>
  <div>
    <widget-card v-for="(detail, index) in placements" :key="detail._id" :detail="detail" :is-last="index === placements.length - 1" :mini="mini"></widget-card>
  </div>
</template>
<script>
import { computed, onMounted, ref } from '@vue/composition-api'

export default {
  props: {
    mini: {
      type: Boolean,
      default: false
    }
  },
  components: {
    WidgetCard: () => import('@/components/work-study/widgetCard')
  },
  setup (props, { root, emit }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const placements = ref([])
    const isLoaded = ref(false)

    onMounted(async () => {
      let pidm = user.value.pidm
      // if (pidm === '2552') pidm = '1248667'
      placements.value = await root.$store.dispatch('work-study/loadPlacements', { pidm })
      emit('loaded', placements.value.length)
      isLoaded.value = true
    })

    return {
      user,
      placements,
      isLoaded
    }
  }
}
</script>
